code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  font-family: sans-serif;
  margin: 0;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
}

.container {
}

.column {
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
}

.entry {
  width: 100vw;
  height: 100vh;
  max-width:100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

.form-container {
  display: flex;
  position: absolute;
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);
  flex-direction: row;
  width: 85%;
  margin: auto;
  padding: 20px;
}
.form-container input[type="text"],
.form-container textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 13px;
  height: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-container button {
  background-color: rgb(202, 82, 68);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 40px;
  padding: 0px;
  height: 40px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-container button:hover {
  background-color: rgb(192, 72, 58);
}

.column:nth-child(1) {
  background-color: rgba(0, 0, 0, 0.04);
}

.column:nth-child(2) {
  padding: 3rem;
}

.poster-container {
  border: 15px solid rgb(202, 82, 68);
  position: relative;
}

.poster-details {
  padding: 5rem;
  margin: auto;
  text-align: center;
}

.poster-details__title {
  margin-bottom: 0.8rem;
  margin-top: 0;
}

.poster-details__label {
  display: block;
  margin-bottom: 1.5rem;
}

.poster-title {
  color: rgb(202, 82, 68);
  font-size: 3rem;
  margin: 1rem 0 0 0;
  line-height: 1.5;
  text-align: center;
  margin: 0;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
}

.poster-subtitle {
  color: rgb(202, 82, 68);
  font-size: 1.25rem;
  font-weight: 300;
  text-align: center;
  margin: 0 0 0rem 0rem;
  margin-right: 0.5rem;
}

.poster-footer {
  color: rgb(202, 82, 68);
  font-size: 0.75rem;
  text-align: center;
}

.poster-title {
  font-size: 2.5rem;
  word-break: break-all;
}
.poster-subtitle {
  word-break: break-all;
}

.poster-byline {
  text-transform: uppercase;
}

input {
  border: 0;
  border-bottom: 4px solid #2a2a2a;
  background: transparent;
  height: 2rem;
  margin-bottom: 1rem;
  width: 100%;
  font-size: 1rem;
}

input:focus {
  outline: 0;
}

button {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  #color: rgb(181, 101, 118);
  color: rgb(200, 50, 80);
  padding: 1rem 2rem;
  width: 90%;
  font-size: 0.5rem;
}

.rectangle {
  width: 35%;
  height: 30px;
  background-color: rgb(199, 91, 71);
  box-shadow: 3px 2px 4px rgba(119, 71, 60);
  display: flex;
  align-items: center;
}
.rectangle-text {
  font-size: 1.25rem;
  color: #FFFFFF;
  margin-left: 0.5rem;
}
.rectangle-icon {
  margin-left: 0.5rem;
  color: rgb(202, 82, 68);
}

.blank-area-small {
  width: 90%;
  height: 15px;
  margin: 10px;
  background-color: rgb(245, 245, 245);
}
.blank-area-tiny {
  width: 200px;
  margin-top: 1%;
  height: 4%;
  background-color: rgb(245, 245, 245);
}
.blank-area {
  width: 200px;
  height: 100px;
  margin: 10px;
  background-color: rgb(245, 245, 245);
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.grid-container-s2 {
  display: grid;
  grid-template-columns: 75% 25%
}
.grid-container5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.icon-item-left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: 10px;
  margin-right: 2px;
  margin-top: 20px;
}
.icon-item-right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: 2px;
  margin-right: 10px;
  margin-top: 20px;
}
.icon-item-middle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.icon-item-middle2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 4px;
  margin-top: -10px;
  margin-bottom: 0px;
}
.item {
  margin: 4px; /* 元素之间的间距 */
  padding: 0px; /* 元素内部的空白区域 */
  color: rgb(202, 82, 68);
}
.github-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: none;
  user-select: none;
  border: 1px solid #d1d5da;
  border-radius: 6px;
}
.github-button-sp {
  width: 10%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 2px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: none;
  user-select: none;
  border: 1px solid #d1d5da;
  border-radius: 6px;
}
.github-button-back {
  display: inline-flex;
  align-items: center;
  width: 1.5%;
  position: absolute;
  top: 1%;
  left: 1.5%;
  justify-content: center;
  padding: 1.5% 3%;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: none;
  user-select: none;
  border: 1px solid #d1d5da;
  border-radius: 16px;
}

@keyframes blinking-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.blinking-button {
  animation: blinking-animation 2s infinite;
}

.photo-frame {
  position: absolute;
  bottom: 33%;
  left: 50%;
  transform: translateX(-50%);
  width: 180px;
  height: 240px;
  border: 8px solid #f8f9fa;
  padding: 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.photo-frame-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.photo-frame img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.notfound {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  color: rgb(202, 82, 68);
  margin-top: 0.75rem;
}

.gift-button {
  margin-top: 0.85rem;
  background-color: rgb(202, 82, 68);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  padding: 0px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
